.link {
    text-decoration: underline;
}

.text-main {
    font-size: 21px;
}

.text-subtitle {
    font-size: 18px;
}

/* 
UI guidelines:
- all logical constructs are dashed.
- all important parts/interfaces use highlight colours (APIs) and 2px border
- org structures are "rounder"
- products have "corners"
- system internal assets have opacity: 0.7
*/

/* ----- component ------- */

/* Services are more important than other components like libraries. */
.componentIcon-service rect {
    rx: 15;
    stroke-width: 2;
    fill: turquoise;
}



.componentIcon g rect {
    rx: 5;
    stroke-width: 1;
    fill: lightgray;
}

/* TODO: Add other customizations for specTypes */

/* ----- api ------- */

.system-internal {
    fill-opacity: 0.7;
}

.system-interface {
    fill-opacity: 1;
}

.apiIcon {

    &>rect {
        rx: 15;
        stroke-width: 3;
        fill: orange;
    }

    & .topRight {}

    & .topLeft {}
}

.apiIcon-stream {

    & g {
        &>path {
            fill: rgb(222, 222, 17)
        }
    }

    & .topRight {}

    & .topLeft {}
}

/* ----- system ------- */
.systemIcon rect {
    rx: 20;
    stroke-width: 2;
    stroke-dasharray: 8, 3;
    fill: rgb(87, 165, 228);
    fill-opacity: 0.4;
}

.systemIcon text {
    fill: aqua;

}

/* ------ resources -------*/
.resourceIcon-datalake-table path {
    stroke-width: 3;
    fill: yellowgreen;
    fill-opacity: 0.9;
}

/* ----- org ------- */
.groupIcon rect {
    /* Visually identify groups by being more "rounder" */
    rx: 40;

}

.groupIcon-team rect {
    stroke-width: 2;
    stroke-dasharray: 5, 2;
}

.groupIcon-group rect {
    stroke-width: 5;
    stroke-dasharray: 15, 15;
}

.userIcon rect {
    rx: 40;
}

.userIcon text tspan {
    font-size: 12px;
}

[title="Rerun build"] {
    display: none !important;
}
